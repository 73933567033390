<template>
  <div class="single-project">
    <section class="standard-page container">
      <div class="standard-page__inner">
        <h2
          class="standard-page__heading heading"
          style="text-transform:capitalize"
        >
          {{title}}
        </h2>
        <v-card
          v-if="isLogedIn"
          outlined
          class="mb24"
        >
          <v-tabs
            fixed-tabs
            background-color="#f9fbfd"
            center-active
            show-arrows
          >

            <v-tab
              to="/personal/documentacion"
              v-text="'Documentos'"
            />
            <v-tab
              to="/personal/vacaciones"
              v-text="'Vacaciones'"
            />
            <v-tab
              to="/personal/perfil"
              v-text="'Perfil'"
            />
            <v-tab
              @click="_cerrarSesion"
              v-text="'Salir'"
            />
          </v-tabs>
        </v-card>
        <vue-page-transition name="fade">
          <router-view
            @msg="$emit('msg',$event)"
            @httpError="$emit('httpError',$event)"
            @login="_handleSesion"
          />
        </vue-page-transition>
      </div>
      <br><br><br><br>
    </section>
  </div>
</template>
<script>
import standard from "@/mixins/standard";
import { Auth } from "aws-amplify";
export default {
  name: "PersonalMain",
  mixins: [standard],
  data() {
    return {
      showLogin: false,
      isLogedIn: false,
      tabs: null,
      title: "Personal",
    };
  },
  created() {
    this._handleSesion();
  },
  methods: {
    _handleSesion(event) {
      console.log("_handleSesion");
      console.log(this.$session.exists());
      console.log(this.$session.has("tipo"));
      console.log(this.$session.get("tipo"));

      if (
        !this.$session.exists() ||
        !this.$session.has("tipo") ||
        this.$session.get("tipo") != "personal"
      ) {
        console.log("cerrar sesion");
        // this._cerrarSesion
      } else {
        this.$headers.headers.Token = this.$session.get("token");
        if (this.$route.name == "PersonalLogin")
          this.$router.push("/personal/perfil");
        this.showLogin = false;
        this.isLogedIn = true;
      }
    },
    _cerrarSesion() {
      this.showLogin = true;
      this.isLogedIn = false;
      Auth.signOut();
      this.$session.destroy();
      this.$router.push("/personal");
    },
  },
  watch: {
    $route(a, b) {
      this._handleSesion();
      // this.title = this.$session.exists() ? this.$session.get("nombre").toLowerCase() : "Personal";
    },
  },
};
</script>